import React from 'react';
import './Board.css';
import DiceArea from '../DiceArea/DiceArea';
import getCheckers from '../getCheckers/getCheckers';
import Triangle from './Triangle/Triangle';

const board = (props) => {

    const {
        triangleClick,
        gameStatus,
        isP1,
        handleStart,
        startDice,
        isYourTurn,
        p1IsNext,
        isLongBackgammon,
    } = props;

    // console.log('props ', props);

    let leftDiceArea = null;
    let rigthDiceArea = null;
    if (gameStatus > 10 && gameStatus <= 59) {
        
        // leftDiceArea = props.p1IsNext ? <DiceArea dice={props.dice} clicked={props.rollDice} gameStatus={props.gameStatus}/> : '';
        // rigthDiceArea = props.p1IsNext ? '' : <DiceArea dice={props.dice} clicked={props.rollDice} gameStatus={props.gameStatus} />;
        let content = <DiceArea dice={props.dice} clicked={props.rollDice} gameStatus={props.gameStatus} color={isP1 ? !p1IsNext ? 'black' : 'white' : p1IsNext ? 'white' : 'black'}/>;

        leftDiceArea = !isP1 ? !p1IsNext ? content : '' : p1IsNext ? content : '';
        rigthDiceArea = !isP1 ? p1IsNext ? content : '' : !p1IsNext ? content : '';

        

        if (!props.dice[0]) {
            if (isP1) {
                if (p1IsNext) {
                    leftDiceArea = '';  
                    rigthDiceArea = '';
                }
            } else {
                if (!p1IsNext) {
                    leftDiceArea = '';  
                    rigthDiceArea = '';
                }
            }
        }

        if (props.gameStatus === 50 ) { //No moves available
            if (isP1) {
                if (props.p1IsNext) {
                    rigthDiceArea =  !isYourTurn ? <DiceArea color={isP1 ? !p1IsNext ? 'black' : 'white' : p1IsNext ? 'white' : 'black'} dice={[0]} clicked={props.rollDice} gameStatus={51}/> : ''; //Status 51 creates a escapes the no moves available button
                } else {
                    leftDiceArea =  !isYourTurn ? <DiceArea color={isP1 ? !p1IsNext ? 'black' : 'white' : p1IsNext ? 'white' : 'black'} dice={[0]} clicked={props.rollDice} gameStatus={51}/> : ''; //Status 51 creates a escapes the no moves available button
                }    
            } else {
                if (props.p1IsNext) {
                    leftDiceArea =  !isYourTurn ? <DiceArea color={isP1 ? !p1IsNext ? 'black' : 'white' : p1IsNext ? 'white' : 'black'} dice={[0]} clicked={props.rollDice} gameStatus={51}/> : ''; //Status 51 creates a escapes the no moves available button
                    // rigthDiceArea = content;
                } else {
                    rigthDiceArea =  !isYourTurn ? <DiceArea color={isP1 ? !p1IsNext ? 'black' : 'white' : p1IsNext ? 'white' : 'black'} dice={[0]} clicked={props.rollDice} gameStatus={51}/> : ''; //Status 51 creates a escapes the no moves available button
                }
    
            }
        } 

        // leftDiceArea =  <DiceArea color={'white'} dice={[2, 2, 2, 2]} clicked={props.rollDice} gameStatus={50}/> ;
        // if (!props.dice[0]) {
        //     if (!props.isP1) {
        //         leftDiceArea = '';                
        //     } else {
        //         rigthDiceArea = '';                
        //     }
        // }

        // if (!props.dice[0]) {
        //     if (props.isP1) {
        //         leftDiceArea = '';                
        //     } else {
        //         rigthDiceArea = '';                
        //     }
        // }

    }


    if (gameStatus === 80) {
        leftDiceArea = <DiceArea dice={startDice.p1} color='black' clicked={isP1 ? handleStart : null } gameStatus={80}/>;
        rigthDiceArea = <DiceArea dice={startDice.p2} color='white' clicked={!isP1 ? handleStart : null} gameStatus={80}/>;
    }

//     return (

//         <div id="board" >

//             <div id="leftSide" >

//                 {leftDiceArea}

//                 {!!props.isP1 ? (
//                 <div className="blocksUp">

//                     <Triangle color="1" position="top"
//                         canMove={props.points[12].canMove}
//                         canReceive={props.points[12].canReceive}
//                         triangleClick={triangleClick}
//                         number={12}
//                     >
//                         {getCheckers(props.points[12].player, props.points[12].checkers, "board", props.points[12].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="top"
//                         canMove={props.points[13].canMove}
//                         canReceive={props.points[13].canReceive}
//                         triangleClick={triangleClick}
//                         number={13}
//                     >
//                         {getCheckers(props.points[13].player, props.points[13].checkers, "board", props.points[13].canMove)}
//                     </Triangle>

//                     <Triangle color="1" position="top"
//                         canMove={props.points[14].canMove}
//                         canReceive={props.points[14].canReceive}
//                         triangleClick={triangleClick}
//                         number={14}
//                     >
//                         {getCheckers(props.points[14].player, props.points[14].checkers, "board", props.points[14].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="top"
//                         canMove={props.points[15].canMove}
//                         canReceive={props.points[15].canReceive}
//                         triangleClick={triangleClick}
//                         number={15}
//                     >
//                         {getCheckers(props.points[15].player, props.points[15].checkers, "board", props.points[15].canMove)}
//                     </Triangle>

//                     <Triangle color="1" position="top"
//                         canMove={props.points[16].canMove}
//                         canReceive={props.points[16].canReceive}
//                         triangleClick={triangleClick}
//                         number={16}
//                     >
//                         {getCheckers(props.points[16].player, props.points[16].checkers, "board", props.points[16].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="top"
//                         canMove={props.points[17].canMove}
//                         canReceive={props.points[17].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={17}
//                     >
//                         {getCheckers(props.points[17].player, props.points[17].checkers, "board", props.points[17].canMove)}
//                     </Triangle>

//                 </div>) : (

//                     <div className="blocksUp">

//                     <Triangle color="1" position="top"
//                         canMove={props.points[11].canMove}
//                         canReceive={props.points[11].canReceive}
//                         triangleClick={triangleClick}
//                         number={11}
//                     >
//                         {getCheckers(props.points[11].player, props.points[11].checkers, "board", props.points[11].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="top"
//                         canMove={props.points[10].canMove}
//                         canReceive={props.points[10].canReceive}
//                         triangleClick={triangleClick}
//                         number={10}
//                     >
//                         {getCheckers(props.points[10].player, props.points[10].checkers, "board", props.points[10].canMove)}
//                     </Triangle>

//                     <Triangle color="1" position="top"
//                         canMove={props.points[9].canMove}
//                         canReceive={props.points[9].canReceive}
//                         triangleClick={triangleClick}
//                         number={9}
//                     >
//                         {getCheckers(props.points[9].player, props.points[9].checkers, "board", props.points[9].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="top"
//                         canMove={props.points[8].canMove}
//                         canReceive={props.points[8].canReceive}
//                         triangleClick={triangleClick}
//                         number={8}
//                     >
//                         {getCheckers(props.points[8].player, props.points[8].checkers, "board", props.points[8].canMove)}
//                     </Triangle>

//                     <Triangle color="1" position="top"
//                         canMove={props.points[7].canMove}
//                         canReceive={props.points[7].canReceive}
//                         triangleClick={triangleClick}
//                         number={7}
//                     >
//                         {getCheckers(props.points[7].player, props.points[7].checkers, "board", props.points[7].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="top"
//                         canMove={props.points[6].canMove}
//                         canReceive={props.points[6].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={6}
//                     >
//                         {getCheckers(props.points[6].player, props.points[6].checkers, "board", props.points[6].canMove)}
//                     </Triangle>

//                 </div>
//                 )}

//                                 {
//                     !!props.isP1 ? (
//                 <div className="blocksDown">

//                     <Triangle color="1" position="bottom"
//                         canMove={props.points[11].canMove}
//                         canReceive={props.points[11].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={11}
//                     >
//                         {getCheckers(props.points[11].player, props.points[11].checkers, "board", props.points[11].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="bottom"
//                         canMove={props.points[10].canMove}
//                         canReceive={props.points[10].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={10}
//                     >
//                         {getCheckers(props.points[10].player, props.points[10].checkers, "board", props.points[10].canMove)}
//                     </Triangle>

//                     <Triangle color="1" position="bottom"
//                         canMove={props.points[9].canMove}
//                         canReceive={props.points[9].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={9}
//                     >
//                         {getCheckers(props.points[9].player, props.points[9].checkers, "board", props.points[9].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="bottom"
//                         canMove={props.points[8].canMove}
//                         canReceive={props.points[8].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={8}
//                     >
//                         {getCheckers(props.points[8].player, props.points[8].checkers, "board", props.points[8].canMove)}
//                     </Triangle>

//                     <Triangle color="1" position="bottom"
//                         canMove={props.points[7].canMove}
//                         canReceive={props.points[7].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={7}
//                     >
//                         {getCheckers(props.points[7].player, props.points[7].checkers, "board", props.points[7].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="bottom"
//                         canMove={props.points[6].canMove}
//                         canReceive={props.points[6].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={6}
//                     >
//                         {getCheckers(props.points[6].player, props.points[6].checkers, "board", props.points[6].canMove)}
//                     </Triangle>
//                 </div>) : (
//                     <div className="blocksDown">

//                     <Triangle color="1" position="bottom"
//                         canMove={props.points[12].canMove}
//                         canReceive={props.points[12].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={12}
//                     >
//                         {getCheckers(props.points[12].player, props.points[12].checkers, "board", props.points[12].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="bottom"
//                         canMove={props.points[13].canMove}
//                         canReceive={props.points[13].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={13}
//                     >
//                         {getCheckers(props.points[13].player, props.points[13].checkers, "board", props.points[13].canMove)}
//                     </Triangle>

//                     <Triangle color="1" position="bottom"
//                         canMove={props.points[14].canMove}
//                         canReceive={props.points[14].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={14}
//                     >
//                         {getCheckers(props.points[14].player, props.points[14].checkers, "board", props.points[14].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="bottom"
//                         canMove={props.points[15].canMove}
//                         canReceive={props.points[15].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={15}
//                     >
//                         {getCheckers(props.points[15].player, props.points[15].checkers, "board", props.points[15].canMove)}
//                     </Triangle>

//                     <Triangle color="1" position="bottom"
//                         canMove={props.points[16].canMove}
//                         canReceive={props.points[16].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={16}
//                     >
//                         {getCheckers(props.points[16].player, props.points[16].checkers, "board", props.points[16].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="bottom"
//                         canMove={props.points[17].canMove}
//                         canReceive={props.points[17].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={17}
//                     >
//                         {getCheckers(props.points[17].player, props.points[17].checkers, "board", props.points[17].canMove)}
//                     </Triangle>
//                 </div>
//                 )}

//             </div>

//             {props.children}

//             <div id="rightSide" >

//                 {rigthDiceArea}

//                 {
//                     !!props.isP1 ? (
//                 <div className="blocksUp">

//                     <Triangle color="1" position="top"
//                         canMove={props.points[18].canMove}
//                         canReceive={props.points[18].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={18}
//                     >
//                         {getCheckers(props.points[18].player, props.points[18].checkers, "board", props.points[18].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="top"
//                         canMove={props.points[19].canMove}
//                         canReceive={props.points[19].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={19}
//                     >
//                         {getCheckers(props.points[19].player, props.points[19].checkers, "board", props.points[19].canMove)}
//                     </Triangle>

//                     <Triangle color="1" position="top"
//                         canMove={props.points[20].canMove}
//                         canReceive={props.points[20].canReceive}
//                         triangleClick={triangleClick}
//                         number={20}
//                     >
//                         {getCheckers(props.points[20].player, props.points[20].checkers, "board", props.points[20].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="top"
//                         canMove={props.points[21].canMove}
//                         canReceive={props.points[21].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={21}
//                     >
//                         {getCheckers(props.points[21].player, props.points[21].checkers, "board", props.points[21].canMove)}
//                     </Triangle>

//                     <Triangle color="1" position="top"
//                         canMove={props.points[22].canMove}
//                         canReceive={props.points[22].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={22}
//                     >
//                         {getCheckers(props.points[22].player, props.points[22].checkers, "board", props.points[22].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="top"
//                         canMove={props.points[23].canMove}
//                         canReceive={props.points[23].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={23}
//                     >
//                         {getCheckers(props.points[23].player, props.points[23].checkers, "board", props.points[23].canMove)}
//                     </Triangle>
//                 </div>
//                 ) : (
//                    <div className="blocksUp">
//                     <Triangle color="1" position="top"
//                         canMove={props.points[5].canMove}
//                         canReceive={props.points[5].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={5}
//                     >
//                         {getCheckers(props.points[5].player, props.points[5].checkers, "board", props.points[5].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="top"
//                         canMove={props.points[4].canMove}
//                         canReceive={props.points[4].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={4}
//                     >
//                         {getCheckers(props.points[4].player, props.points[4].checkers, "board", props.points[4].canMove)}
//                     </Triangle>

//                     <Triangle color="1" position="top"
//                         canMove={props.points[3].canMove}
//                         canReceive={props.points[3].canReceive}
//                         triangleClick={triangleClick}
//                         number={3}
//                     >
//                         {getCheckers(props.points[3].player, props.points[3].checkers, "board", props.points[3].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="top"
//                         canMove={props.points[2].canMove}
//                         canReceive={props.points[2].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={2}
//                     >
//                         {getCheckers(props.points[2].player, props.points[2].checkers, "board", props.points[2].canMove)}
//                     </Triangle>

//                     <Triangle color="1" position="top"
//                         canMove={props.points[1].canMove}
//                         canReceive={props.points[1].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={1}
//                     >
//                         {getCheckers(props.points[1].player, props.points[1].checkers, "board", props.points[1].canMove)}
//                     </Triangle>

//                     <Triangle color="2" position="top"
//                         canMove={props.points[0].canMove}
//                         canReceive={props.points[0].canReceive}
//                         triangleClick={triangleClick}                        
//                         number={0}
//                     >
//                         {getCheckers(props.points[0].player, props.points[0].checkers, "board", props.points[0].canMove)}
//                     </Triangle>
//                 </div>
//                 )}
//                 {
//                     !!props.isP1 ? (
//                         <div className="blocksDown">

//                             <Triangle color="1" position="bottom"
//                                 canMove={props.points[5].canMove}
//                                 canReceive={props.points[5].canReceive}
//                                 triangleClick={triangleClick}                        
//                                 number={5}
//                             >
//                                 {getCheckers(props.points[5].player, props.points[5].checkers, "board", props.points[5].canMove)}
//                             </Triangle>

//                             <Triangle color="2" position="bottom"
//                                 canMove={props.points[4].canMove}
//                                 canReceive={props.points[4].canReceive}
//                                 triangleClick={triangleClick}                        
//                                 number={4}
//                             >
//                                 {getCheckers(props.points[4].player, props.points[4].checkers, "board", props.points[4].canMove)}
//                             </Triangle>

//                             <Triangle color="1" position="bottom"
//                                 canMove={props.points[3].canMove}
//                                 canReceive={props.points[3].canReceive}
//                                 triangleClick={triangleClick}                        
//                                 number={3}
//                             >
//                                 {getCheckers(props.points[3].player, props.points[3].checkers, "board", props.points[3].canMove)}
//                             </Triangle>

//                             <Triangle color="2" position="bottom"
//                                 canMove={props.points[2].canMove}
//                                 canReceive={props.points[2].canReceive}
//                                 triangleClick={triangleClick}                        
//                                 number={2}
//                             >
//                                 {getCheckers(props.points[2].player, props.points[2].checkers, "board", props.points[2].canMove)}
//                             </Triangle>

//                             <Triangle color="1" position="bottom"
//                                 canMove={props.points[1].canMove}
//                                 canReceive={props.points[1].canReceive}
//                                 triangleClick={triangleClick}                        
//                                 number={1}
//                             >
//                                 {getCheckers(props.points[1].player, props.points[1].checkers, "board", props.points[1].canMove)}
//                             </Triangle>

//                             <Triangle color="2" position="bottom"
//                                 canMove={props.points[0].canMove}
//                                 canReceive={props.points[0].canReceive}
//                                 triangleClick={triangleClick}                        
//                                 number={0}
//                             >
//                                 {getCheckers(props.points[0].player, props.points[0].checkers, "board", props.points[0].canMove)}
//                             </Triangle>

//                         </div>
//                     ) : (
//                         <div className="blocksDown">
//                             <Triangle color="1" position="bottom"
//                                 canMove={props.points[18].canMove}
//                                 canReceive={props.points[18].canReceive}
//                                 triangleClick={triangleClick}                        
//                                 number={18}
//                             >
//                                 {getCheckers(props.points[18].player, props.points[18].checkers, "board", props.points[18].canMove)}
//                             </Triangle>

//                             <Triangle color="2" position="bottom"
//                                 canMove={props.points[19].canMove}
//                                 canReceive={props.points[19].canReceive}
//                                 triangleClick={triangleClick}                        
//                                 number={19}
//                             >
//                                 {getCheckers(props.points[19].player, props.points[19].checkers, "board", props.points[19].canMove)}
//                             </Triangle>

//                             <Triangle color="1" position="bottom"
//                                 canMove={props.points[20].canMove}
//                                 canReceive={props.points[20].canReceive}
//                                 triangleClick={triangleClick}                        
//                                 number={20}
//                             >
//                                 {getCheckers(props.points[20].player, props.points[20].checkers, "board", props.points[20].canMove)}
//                             </Triangle>

//                             <Triangle color="2" position="bottom"
//                                 canMove={props.points[21].canMove}
//                                 canReceive={props.points[21].canReceive}
//                                 triangleClick={triangleClick}                        
//                                 number={21}
//                             >
//                                 {getCheckers(props.points[21].player, props.points[21].checkers, "board", props.points[21].canMove)}
//                             </Triangle>

//                             <Triangle color="1" position="bottom"
//                                 canMove={props.points[22].canMove}
//                                 canReceive={props.points[22].canReceive}
//                                 triangleClick={triangleClick}                        
//                                 number={22}
//                             >
//                                 {getCheckers(props.points[22].player, props.points[22].checkers, "board", props.points[22].canMove)}
//                             </Triangle>

//                             <Triangle color="2" position="bottom"
//                                 canMove={props.points[23].canMove}
//                                 canReceive={props.points[23].canReceive}
//                                 triangleClick={triangleClick}                        
//                                 number={23}
//                             >
//                                 {getCheckers(props.points[23].player, props.points[23].checkers, "board", props.points[23].canMove)}
//                             </Triangle>

//                         </div>
//                     )
//                 }

//             </div>



//         </div>
//    );

    const renderSection = (from, to, position) => {
        const step = from > to ? -1 : 1;
        const numbers = [];
        // for(let n = from; n += step; step < 0 ? n < to : n > to) {
        //     numbers.push(n);
        // }
        let n = from;
        while(step < 0 ? n >= to : n <= to) {
            numbers.push(n);
            n += step; 
        } 

        return numbers.map((n, index) => {
            // console.log('color ', index % 2 == 0 ? '1': '2')
            return (
                <Triangle
                    color={'2'} 
                    key={n}
                    position={position}
                    canMove={props.points[n].canMove}
                    canReceive={props.points[n].canReceive}
                    triangleClick={triangleClick}                        
                    number={n}
                >
                    {/* {n} */}
                    {getCheckers(props.points[n].player, props.points[n].checkers, "board", props.points[n].canMove)}
                </Triangle>
            )
        })
    }


    if (isLongBackgammon) {
        if (!isP1) {
            return (
                <div id="board" >
                    <div id="leftSide" >
                        <div className="blocksUp">
                            {renderSection(11, 6, 'top')}
                        </div>
         
                        {leftDiceArea}
         
                        <div className="blocksDown">
                            {renderSection(12, 17, 'bottom')}
                        </div>
                    </div>
                    
                    {props.children}
        
                    <div id="rightSide" >
                        <div className="blocksUp">
                            {renderSection(5, 0, 'top')}
                        </div>
                        
                        {rigthDiceArea}
        
                        <div className="blocksDown">
                            {renderSection(18, 23, 'bottom')}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div id="board" >
                    <div id="leftSide" >
                        <div className="blocksUp">
                            {renderSection(23, 18, 'top')} 

                        </div>
         
                        {leftDiceArea}
         
                        <div className="blocksDown">
                            
                        {renderSection(0, 5, 'bottom')}
                           
                        </div>
                    </div>
                    
                    {props.children}
        
                    <div id="rightSide" >
                        <div className="blocksUp">
                            {renderSection(17, 12, 'top')}
                        </div>
                        
                        {rigthDiceArea}
        
                        <div className="blocksDown">
                            {renderSection(6, 11, 'bottom')}
                        </div>
                    </div>
                </div>
            )
        }
    }

    if (!isP1) {
        return (
            <div id="board" >
                <div id="leftSide" >
                    <div className="blocksUp">
                        {renderSection(11, 6, 'top')}
                    </div>
     
                    {leftDiceArea}
     
                    <div className="blocksDown">
                        {renderSection(12, 17, 'bottom')}
                    </div>
                </div>
                
                {props.children}
    
                <div id="rightSide" >
                    <div className="blocksUp">
                        {renderSection(5, 0, 'top')}
                    </div>
                    
                    {rigthDiceArea}
    
                    <div className="blocksDown">
                        {renderSection(18, 23, 'bottom')}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div id="board" >
                <div id="leftSide" >
                    <div className="blocksUp">
                        {renderSection(12, 17, 'top')}
                    </div>
     
                    {leftDiceArea}
     
                    <div className="blocksDown">
                        {renderSection(11, 6, 'bottom')}
                    </div>
                </div>
                
                {props.children}
    
                <div id="rightSide" >
                    <div className="blocksUp">
                        {renderSection(18, 23, 'top')}
                    </div>
                    
                    {rigthDiceArea}
    
                    <div className="blocksDown">
                        {renderSection(5, 0, 'bottom')}
                    </div>
                </div>
            </div>
        )
    }
}

export default board;