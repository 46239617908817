import Reac, {
	useEffect,
	useState
} from 'react';

import Axios from "axios";
import { Link } from "react-router-dom";

const GameList = () => {
	const [games, setGames] = useState([]);

	const fetchGames = () => {
		Axios.get('/api/games').then(res => {
			setGames(Object.values(res.data))
		})		
	}

	useEffect(() => {
		fetchGames();
	}, [])

	const handleCreate = () => {
		//avatartUrl: 'http://backgammon.lol/avatar/get-avatar?file_id=AQADAgADr6cxG-7rwwcACAIAA-7rwwcABNbP4kqJt_MdNgQ'
		const users = [
			{ username: 'Player1', id: 1 },
			{ username: 'Player2', id: 2 },
		];

		Axios.post('/api/games', { game_key: 'key'+  Math.random().toString(36).substring(2, 8), users, isLongBackgammon: false }).then(fetchGames);
	}

	const handleCreateLong = () => {
		const users = [
			{ username: 'Player1', id: 1 },
			{ username: 'Player2', id: 2 },
		];

		Axios.post('/api/games', { game_key: 'key'+  Math.random().toString(36).substring(2, 8), users, isLongBackgammon: true }).then(fetchGames);

	}

	const handleRemove = (id) => {
		Axios.delete('/api/games/' + id).then(fetchGames);		
	}

	const handleDeleteALL = () => {
		Axios.delete('/api/delete-all-games').then(fetchGames);		
	}

	return (
		<div>
			<button onClick={handleCreate}>new Game</button>
			<button onClick={handleCreateLong}>new Game long</button>
			<button onClick={handleDeleteALL}>delete all</button>
			<h2>count: {games.length}</h2>
			<div>
				{games.map(game => (
					<div key={game.id}>
						<p>id:{game.id}</p>
						{game.isLongBackgammon ? 'long': 'short'}
						<p>status: {game.gameStatus}</p>
						<Link target="_blank" rel="noopener noreferrer" to={`/games/${game.id}/1`}>Player1</Link>
						<Link target="_blank" rel="noopener noreferrer" to={`/games/${game.id}/2`}>Player2</Link>
						<button onClick={() => handleRemove(game.id)}>remove</button>
					</div>
				))}
			</div>
		</div>
	)
}

export default GameList;