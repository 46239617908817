import React, { useEffect } from 'react';

import './Menu.css';

const telegram = window.Telegram.WebApp;

const Menu = () => {
    const games = [
        {
            id: 1,
            name: 'backgammon_short',
            title: 'Нарды короткие'
        },
        {
            id: 2,
            name: 'backgammon_long',
            title: 'Наоды длинные'
        }
    ];

    useEffect(() => {
        telegram.ready();
    }, [])

    const freePlay = (game) => {
        telegram.sendData('free-' + game.name);
        telegram.close();
    }

    const paidPlay = (game) => {
        telegram.sendData('paid-' + game.name);
        telegram.close();
    }

    const items = games.map(item => (
        <div id={item.id} className='menu__item'>
            <h4 className='menu__item-title'>{item.title}</h4>
            <div className='menu__item-buttons'>
                <button onClick={() => freePlay(item)}>Играть</button>
                <button onClick={() => paidPlay(item)}>Играть на TON</button>
            </div>
        </div>
    ))

    return (
        <div id="App">
            <div className='menu'>
                {items}
            </div>
        </div>
    )
}

export default Menu;