import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
// import ShadowApp from './containers/ShadowApp';
import GameList from './containers/GameList';
import Test from './containers/Test';
import Menu from './containers/Menu';

import { HashRouter, BrowserRouter, Routes, Route } from "react-router-dom";


ReactDOM.render(
	<BrowserRouter>
		<Routes>
			<Route index element={<GameList />} />
			<Route path="games/:id/1" element={<App player={1} />} />
			<Route path="games/:id/2" element={<App player={2} />} />
			<Route path="test" element={<Test />} />
			<Route path="menu" element={<Menu />} />
		</Routes>
	</BrowserRouter>, 
	document.getElementById('root')
);

// document.getElementById('root').innerHTML = 'heeelo'
// document.getElementById('root').style.color = 'red'