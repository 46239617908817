import React from 'react';

import styles from './styles.module.css';

const Triangle = ({
    number,
    color
}) => {
    const checkers = [];
    for (let i = 0; i < number; i++) {
        if (i < 5) {
            checkers.push(<div className={styles["checker"] + ' ' + styles[color]}></div>)
        } else {
            checkers.push(<div className={styles["checker-count"] + ' ' + styles[color]}>{number}</div>);
            break;
        }
    }

    return (
        <div className={styles.triangle} data-checker-count={number}>
            {checkers}
        </div>
    )
}

const Test = () => {
    return (
        
<div className={styles['board-container']} >
  <div className={styles.board}>
    <div className={styles['left-board']} >
      <div  className={styles['top-half']}>
        <Triangle color={"white"} number={6}/>
        <Triangle color={"black"} number={15}/>
        <Triangle color={"black"} number={1}/>
        <Triangle color={"white"} number={1}/>
        <Triangle color={"black"} number={1}/>
        <Triangle color={"white"} number={1}/>
      </div>
      <div className={styles["bottom-half"]}>
        <Triangle color={"black"} number={1}/>
        <Triangle color={"white"} number={1}/>
        <Triangle color={"black"} number={1}/>
        <Triangle color={"white"} number={1}/>
        <Triangle color={"black"} number={1}/>
        <Triangle color={"white"} number={1}/>
      </div>
    </div>
    
    <div className={styles["center-gap"]}>
      <div className={styles["fold-line"]}></div>
      <div className={styles["hinge"] + ' ' + styles["top-hinge"]}></div>
      <div className={styles["hinge"] + ' ' + styles["bottom-hinge"]}></div>
    </div>

    <div className={styles['right-board']}>
        <div className={styles["top-half"]}>
            <Triangle color={"black"} number={1}/>
            <Triangle color={"white"} number={1}/>
            <Triangle color={"black"} number={1}/>
            <Triangle color={"white"} number={1}/>
            <Triangle color={"black"} number={1}/>
            <Triangle color={"white"} number={1}/>
        </div>
        <div class={styles["bottom-half"]}>
            <Triangle color={"black"} number={1}/>
            <Triangle color={"white"} number={7}/>
            <Triangle color={"black"} number={1}/>
            <Triangle color={"white"} number={1}/>
            <Triangle color={"black"} number={1}/>
            <Triangle color={"white"} number={1}/>

        </div>
    </div>
  </div>
</div>
    )
}

export default Test;